import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useForm, usePage } from '@inertiajs/vue3'
import {
    AssetsDTO,
    DepartmentDTO,
    FloorDTO,
    LocationDTO,
    RoomDTO,
    StakeholderDTO,
} from '@Types'

export const useLocationStore = defineStore('locationStore', () => {
    const organisationId = computed(
        () => usePage().props.current_organisation_id
    )
    const location = ref<LocationDTO>({
        address: null,
        city: null,
        elevators: 0,
        elevatorsAreCentral: false,
        freightElevator: false,
        rbsFilePresent: false,
        email: null,
        floors: [],
        stakeholders: [],
        assets: {} as AssetsDTO,
        room: {} as RoomDTO,
        mainLocation: false,
        zipcode: null,
        name: '',
        id: 0,
    })

    const form = useForm({
        organisation_id: organisationId.value,
        address: location.value.address,
        city: location.value.city,
        elevators: location.value.elevators,
        elevatorsAreCentral: location.value.elevatorsAreCentral,
        freightElevator: location.value.freightElevator,
        rbsFilePresent: location.value.rbsFilePresent,
        email: location.value.email,
        floors: location.value.floors,
        stakeholders: location.value.stakeholders,
        assets: location.value.assets,
        room: location.value.room,
        mainLocation: location.value.mainLocation,
        zipcode: location.value.zipcode,
        name: location.value.name,
        id: location.value.id > 0 ? location.value.id : null,
    })

    const setup = (activeLocation: LocationDTO) => {
        location.value = activeLocation
        form.address = location.value.address
        form.city = location.value.city
        form.elevators = location.value.elevators
        form.elevatorsAreCentral = location.value.elevatorsAreCentral
        form.freightElevator = location.value.freightElevator
        form.rbsFilePresent = location.value.rbsFilePresent
        form.email = location.value.email
        form.floors = location.value.floors
        form.stakeholders = location.value.stakeholders
        form.assets = location.value.assets
        form.mainLocation = location.value.mainLocation
        form.zipcode = location.value.zipcode
        form.name = location.value.name
        form.id = location.value.id
    }

    const updateFloor = (newFloor: FloorDTO) => {
        form.floors = form.floors.map((f) =>
            f.id === newFloor.id ? newFloor : f
        )
    }

    const updateStakeholder = (newStakeholder: StakeholderDTO) => {
        form.stakeholders = form.stakeholders.map((f) =>
            f.id === newStakeholder.id ? newStakeholder : f
        )
    }

    const updateRoom = (newRoom: RoomDTO) => {
        form.room = newRoom
    }

    const updateAssets = (newAsset: AssetsDTO) => {
        form.assets = newAsset
    }

    const mutateLocation = () => {
        //
        form.floors.forEach((f: FloorDTO) => {
            f.id = (f.id ?? 0) > 0 ? f.id : null
            f.departments.forEach(
                (d: DepartmentDTO) => (d.id = (d.id ?? 0) > 0 ? d.id : null)
            )
        })

        form.stakeholders.forEach((f: StakeholderDTO) => {
            f.id = (f.id ?? 0) > 0 ? f.id : null
        })
        form.elevators = form.elevators > 0 ? form.elevators : 0
        form.post(route('locations.store', { location: location.value.id }), {
            onSuccess: () => {
                window.location.reload()
            },
        })
    }

    return {
        location,
        form,
        organisationId,
        updateFloor,
        updateRoom,
        updateAssets,
        updateStakeholder,
        setup,
        mutateLocation,
    }
})
