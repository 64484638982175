<script setup lang="ts">
import InputLabel from '@/Components/InputLabel.vue'
import { useLocationStore } from '@/Pages/Location/stores/useLocationStore'
import SwitchCheckBox from '@/Components/SwitchCheckBox.vue'

const store = useLocationStore()
</script>

<template>
    <div class="flex flex-col px-10">

        <div class="mt-4 flex flex-row space-x-6">
            <InputLabel
                for="rbsFilePresent"
                value="RBS bestand CSU aanwezig"
            />
            <div class="my-auto flex justify-end">
                <SwitchCheckBox v-model="store.form.rbsFilePresent" />
            </div>
        </div>

    </div>
</template>

<style scoped>

</style>
